// Menu.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation } from 'react-router-dom';

const Menu: React.FC = () => {
  const location = useLocation();

console.log(location.pathname);
  return (
    <List component="nav">
      {location.pathname !== "/login" ? (
        <React.Fragment>
          <ListItemButton component={Link} to="/flats">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Flats" />
          </ListItemButton>
          <ListItemButton component={Link} to="/logout">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </React.Fragment>
      ) : (
        <ListItemButton component={Link} to="/login">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItemButton>
      )}
    </List>
  );
};

export default Menu;
