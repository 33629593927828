import { configureStore, createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: { open: false },
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    openDrawer: (state) => {
      state.open = true;
    },
    closeDrawer: (state) => {
      state.open = false;
    }
  }
});

export const { toggleDrawer, openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;