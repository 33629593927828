import { configureStore } from '@reduxjs/toolkit';
import dataTableReducer from './dataTableSlice';
import drawerSliceReducer from './drawerSlice';
import userSliceReducer from './userSlice';
const store = configureStore({
  reducer: {
    dataTable: dataTableReducer,
    drawer: drawerSliceReducer,
    user: userSliceReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
