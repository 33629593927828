// src/components/Login.tsx
import { useDispatch } from 'react-redux';
import { logout } from '../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
console.log("sign out");

  
  useEffect(() => {
    const signout = async() =>
      {
        await signOut(auth);
        dispatch(logout());
        navigate('/login');
      };
      signout();
    }, [dispatch, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;