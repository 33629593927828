// src/api.ts
import axios from 'axios';

const API_URL = 'https://downloadflat.azurewebsites.net/api/GetFlats';
const API_URL_SB = 'https://downloadflat.azurewebsites.net/api/SendRequest';

const API_URL_Local = 'http://localhost:7071/api/GetFlats';

export interface Post {
    id: number;
    title: string;
    body: string;
}

export const fetchData = async (page: number, limit: number, selectedLabels: string[]): Promise<ApiResult> => {
    const response = await axios.get<ApiResult>(API_URL, {
        params: {
            skip: (page - 1) * limit,
            top: limit,
            selectedLabels: selectedLabels,
            code: "3QtDHKtO1OgW34gC5wzJHM4jOba9Yp_q6MipmsV3YJWsAzFu8pHqEw=="
        }
    });

    return response.data;
};

export const sendToServiceBus = async () => {
    var result = await axios.post(API_URL_SB, null,  {
        params: {
            code: "wquW6Nh8QCUu9flyQoAH5QRyfLfZL6KCnahhWJRnp5ozAzFuXx1HaQ=="
        }
    });
};

export interface ApiResult
{
    Flats: FlatAddress[];
    Count: number;
    Labels: string[];
}
export interface FlatAddress {
    Id: string;
    Source: string;
    Name: string;
    Size?: string;
    FloorSize: number;
    Link: string;
    FormattedAddress?: string;
    Location?: string;
    Street?: string;
    Part?: string;
    Price: number;
    CustomLabel?: string;
    ChangedPrice: boolean;
    IsDeleted: boolean;
    IsAvailable: boolean;
    PriceHistory?: string;
    UpdatedDate: Date;
    DownloadedDate: Date;
    IsFavourite: boolean;
  }
   
  
