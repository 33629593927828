import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  user: { id: string; email: string } | null;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean,
  isLoading: boolean
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  isLoading: true
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<{ id: string; email: string }>) {
      state.user = action.payload;
      state.loading = false;
      state.isAuthenticated = true;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;

    },
    setLoading(state, action: PayloadAction<boolean>)
    {
      state.isLoading = action.payload
    },
    saveUser(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setLoading, loginStart, loginSuccess, loginFailure, logout, saveUser } = userSlice.actions;
export default userSlice.reducer;
