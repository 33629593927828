import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_VtIqQ8fT-AbysCzeWkmSKTIS98va1Dk",
  authDomain: "flat-app-3e47e.firebaseapp.com",
  projectId: "flat-app-3e47e",
  storageBucket: "flat-app-3e47e.appspot.com",
  messagingSenderId: "1033318003514",
  appId: "1:1033318003514:web:0d422a1a5bf0c647115603"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
