import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../redux/store";
import { useDemoData } from '@mui/x-data-grid-generator';

import {
  fetchPosts,
  sendToSb,
  setPaginationModel,
} from "../redux/dataTableSlice";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  SelectChangeEvent,
  SelectInputProps,
} from "@mui/material/Select/SelectInput";

const DataTablePage: React.FC = () => {
  const { data: demodata }= useDemoData({
    dataSet: 'Commodity',
    rowLength: 500,
    maxColumns: 6,
  });

  const dispatch = useDispatch<AppDispatch>();
  const {
    sendingToSbStatus,
    data,
    labels,
    paginationModel,
    filters,
    total,
    loading,
    error,
  } = useSelector((state: RootState) => state.dataTable);
  const user = useSelector((state: RootState) => state.user.user);
  const handlePaginationChange = (
    model: GridPaginationModel,
    details: GridCallbackDetails<any>
  ) => {
    dispatch(setPaginationModel({ model, details }));
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedLabels>) => {
    const {
      target: { value },
    } = event;
    setSelectedLabels(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // if (user) {
    dispatch(
      fetchPosts({
        page: paginationModel.page + 1,
        rowsPerPage: paginationModel.pageSize,
        selectedLabels,
      })
    );
    //}
  }, [dispatch, paginationModel, user, selectedLabels]);

  // if (!user) {
  //   return <p>Please log in to view the data table.</p>;
  // }

  const dateGetter = (params: any) => {
    return new Date(params);
  };
  const columns: GridColDef[] = [
    // { field: 'Id', headerName: 'ID', width: 150, },
    {
      field: "Link",
      headerName: "Price",
      width: 150,
      renderCell: (params) => (
        <Link href={params.value} target="_blank" rel="noopener">
          {params.row.Price}
        </Link>
      ),
    },
    { field: "FormattedAddress", headerName: "Address", width: 300 },
    {
      field: "FloorSize",
      headerName: "Floor Size",
      type: "number",
      width: 150,
    },

    { field: "Size", headerName: "Size", width: 100 },
    { field: "CustomLabel", headerName: "Custom Label", width: 150 },
    {
      field: "ChangedPrice",
      headerName: "Changed Price",
      type: "boolean",
      width: 150,
    },
    {
      field: "IsDeleted",
      headerName: "Is Deleted",
      type: "boolean",
      width: 150,
    },
    {
      field: "IsAvailable",
      headerName: "Is Available",
      type: "boolean",
      width: 150,
    },
    { field: "PriceHistory", headerName: "Price History", width: 250 },
    {
      field: "UpdatedDate",
      headerName: "Updated Date",
      type: "dateTime",
      width: 200,
      valueGetter: dateGetter,
    },
    {
      field: "DownloadedDate",
      headerName: "Downloaded Date",
      type: "dateTime",
      width: 200,
      valueGetter: dateGetter,
    },
    { field: "Name", headerName: "Name", width: 400 },

    {
      field: "IsFavourite",
      headerName: "Is Favourite",
      type: "boolean",
      width: 10,
    },
  ];


  return (
    <div style={{ height: '100%', width: '100%'}}>
      <div style={{ marginBottom: "16px" }}>
        <Button
          onClick={async () => {
            dispatch(sendToSb());
          }}
          className="custom-button"
          variant="contained"
        >
          Send to SB
        </Button>
        {sendingToSbStatus && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {sendingToSbStatus}
          </Alert>
        )}
      </div>

      <FormControl sx={{ m: 1, width: 400 }}>
        <InputLabel id="demo-multiple-checkbox-label">Labels</InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedLabels}
          onChange={handleChange}
          input={<OutlinedInput label="Labels" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {labels.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedLabels.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
      <DataGrid
            pageSizeOptions={[5, 10, 25]}

  loading={loading}
  getRowId={(row) => row.Id}
        rows={data}
        columns={columns}
        onPaginationModelChange={(
          model: GridPaginationModel,
          details: GridCallbackDetails<any>
        ) => {
          dispatch(setPaginationModel({ model, details }));
        }}
        pagination
        paginationModel={paginationModel}
        rowCount={total}
        paginationMode="server"
        autoHeight

      />
      </div>
    </div>
  );
};
export default DataTablePage;
